import { Injectable } from '@angular/core';
import { HttpCacheService } from '@dougs/core/http-cache';
import { ServicesCatalog } from '@dougs/services/dto';

@Injectable({
  providedIn: 'root',
})
export class ServicesCacheService extends HttpCacheService<ServicesCatalog> {
  CACHE_DURATION_IN_SECONDS = 3600;
}
