import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import {
  OrderedSectionNames,
  ServiceRepository,
  ServiceTemplate,
  ServiceTemplatePost,
} from '@dougs/revenue/services/dto';
import { ServicesHttpService } from '../http/services.http';

interface CatalogServicesState {
  services: ServiceRepository;
}

@Injectable({
  providedIn: 'root',
})
export class CatalogServicesStateService extends StateService<CatalogServicesState> {
  services$: Observable<ServiceRepository> = this.select((state) => this.sortByDescription(state.services));

  constructor(
    private readonly servicesHttpService: ServicesHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshServices(): Promise<void> {
    try {
      this.setState({
        services: await lastValueFrom(this.servicesHttpService.getAllServices(true)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addService(companyId: number, serviceTemplatePost: ServiceTemplatePost): Promise<ServiceTemplate | null> {
    try {
      return await lastValueFrom(this.servicesHttpService.addServiceTemplate(companyId, serviceTemplatePost));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async editService(companyId: number, serviceTemplatePost: ServiceTemplatePost): Promise<ServiceTemplate | null> {
    try {
      return await lastValueFrom(this.servicesHttpService.editServiceTemplate(companyId, serviceTemplatePost));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getAllServices(): Promise<ServiceRepository | null> {
    try {
      return await lastValueFrom(this.servicesHttpService.getAllServices(false));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  private sortByDescription(serviceRepository: ServiceRepository): ServiceRepository {
    if (!serviceRepository) {
      return {};
    }

    return Object.assign(
      {},
      ...Object.entries(serviceRepository).map(([key, value]) => ({
        [Object.values(OrderedSectionNames).includes(key as OrderedSectionNames) ? key : '']: [...value].sort((a, b) =>
          a.description.localeCompare(b.description),
        ),
      })),
    );
  }
}
