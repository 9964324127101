import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { ServiceRepository, ServiceTemplate, ServiceTemplatePost } from '@dougs/revenue/services/dto';
import { CanProcess, Service, ServicesCatalog, ServicesCatalogSearchResult } from '@dougs/services/dto';
import { BillingInvoice } from '@dougs/subscription/dto';
import { Task } from '@dougs/task/dto';
import { ServicesCacheService } from '../cache/services-cache.service';

@Injectable({
  providedIn: 'root',
})
export class ServicesHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly servicesCacheService: ServicesCacheService,
  ) {}

  getServices(companyId: number): Observable<Service[]> {
    return this.http.get<Service[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/customer-deliveries`,
    );
  }

  getAllServices(showDeprecated: boolean): Observable<ServiceRepository> {
    return this.http.get<ServiceRepository>(
      `${AppConfig.settings.legacyApiServerUrl}/services?showDeprecated=${showDeprecated}`,
    );
  }

  addServiceTemplate(companyId: number, serviceTemplatePost: ServiceTemplatePost): Observable<ServiceTemplate> {
    return this.http.post<ServiceTemplate>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/service-templates`,
      serviceTemplatePost,
    );
  }

  editServiceTemplate(companyId: number, serviceTemplatePost: ServiceTemplatePost): Observable<ServiceTemplate> {
    return this.http.put<ServiceTemplate>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/service-templates/${serviceTemplatePost.code}`,
      serviceTemplatePost,
    );
  }

  getTask(taskId: number): Observable<Task> {
    return this.http.get<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}`);
  }

  getNotificationTask(serviceId: number): Observable<Task | null> {
    return this.http.get<Task | null>(
      `${AppConfig.settings.legacyApiServerUrl}/customer-deliveries/${serviceId}/customer-quote-validation-task`,
    );
  }

  getProcessPayment(companyId: number): Observable<CanProcess> {
    return this.http.get<CanProcess>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/can-process-payment`,
    );
  }

  getBillingInvoice(companyId: number, billingInvoiceId: number): Observable<BillingInvoice> {
    return this.http.get<BillingInvoice>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/billing-invoices/${billingInvoiceId}`,
    );
  }

  postPaymentMethod(taskId: number, paymentMethod: string): Observable<Task> {
    return this.http.post<Task>(
      `${AppConfig.settings.legacyApiServerUrl}/quotes/${taskId}/actions/set-payment-method`,
      { paymentMethod },
    );
  }

  postCreditCard(companyId: number, clientSecret: string): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/actions/change-card`,
      { clientSecret },
    );
  }

  completeTask(taskId: number): Observable<Task> {
    return this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/actions/complete`, {});
  }

  removeAttachment(companyId: number, serviceId: number, fileIds: number[]): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/customer-deliveries/${serviceId}/unprovide-documents`,
      { fileIds },
    );
  }

  postStripeClientSecret(companyId: number): Observable<string> {
    return this.http.post<string>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/subscription/actions/initialize-setup-intent`,
      {},
    );
  }

  getServicesCatalog(companyId: number): Observable<ServicesCatalog> {
    let catalog$: Observable<ServicesCatalog> | null = this.servicesCacheService.getValue(companyId);

    if (!catalog$) {
      catalog$ = this.http
        .get<ServicesCatalog>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/product-catalog`)
        .pipe(shareReplay(1));
      this.servicesCacheService.setValue(catalog$, companyId);
    }

    return catalog$;
  }

  searchServicesInCatalog(companyId: number, search?: string): Observable<ServicesCatalogSearchResult> {
    let params: HttpParams = new HttpParams();

    if (search) {
      params = params.append('search', search);
    }

    return this.http.get<ServicesCatalogSearchResult>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/product-catalog/search`,
      { params },
    );
  }
}
