import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { CanProcess, QuoteStatus, Service, ServicesCatalog, ServicesCatalogSearchResult } from '@dougs/services/dto';
import { BillingInvoice } from '@dougs/subscription/dto';
import { Task, TaskBillingInvoiceItem } from '@dougs/task/dto';
import { ServicesHttpService } from '../http/services.http';

interface ServicesState {
  services: Service[];
  catalog: ServicesCatalog;
  searchResult: ServicesCatalogSearchResult;
}

@Injectable({
  providedIn: 'root',
})
export class ServicesStateService extends StateService<ServicesState> {
  services$ = this.select((state: ServicesState) => state.services);
  draftServices$ = this.select((state: ServicesState) =>
    state.services.filter((service) => service.quoteStatus === QuoteStatus.QUOTE),
  );
  pendingServices$ = this.select((state: ServicesState) =>
    state.services.filter((service) => service.quoteStatus === QuoteStatus.READY && !service.completedAt),
  );
  completedServices$ = this.select((state: ServicesState) =>
    state.services.filter((service) => service.quoteStatus === QuoteStatus.READY && service.completedAt),
  );
  servicesCatalog$: Observable<ServicesCatalog> = this.select((state: ServicesState) => state.catalog);
  servicesCatalogSearchResult$: Observable<ServicesCatalogSearchResult> = this.select(
    (state: ServicesState) => state.searchResult,
  );

  constructor(
    private readonly servicesHttpService: ServicesHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshServices(activeCompany: Company): Promise<void> {
    try {
      const services: Service[] = await lastValueFrom(this.servicesHttpService.getServices(activeCompany.id));
      this.setState({
        services,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removeServiceProvidedDocument(
    activeCompany: Company,
    updatedService: Service,
    fileId: number,
  ): Promise<boolean> {
    try {
      await toPromise(this.servicesHttpService.removeAttachment(activeCompany.id, updatedService.id, [fileId]));
      this.setState({
        services: [
          ...(this.state?.services || []).map((service) =>
            service.id === updatedService.id ? updatedService : service,
          ),
        ],
      });
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async getAssociateNotificationTask(service: Service): Promise<Task | null> {
    try {
      return await lastValueFrom(this.servicesHttpService.getNotificationTask(service.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getBillingInvoice(activeCompany: Company, service: Service): Promise<BillingInvoice | null> {
    try {
      const billingInvoiceItem: TaskBillingInvoiceItem | undefined = service.billingInvoiceItems.pop();

      if (billingInvoiceItem?.billingInvoiceId) {
        return await lastValueFrom(
          this.servicesHttpService.getBillingInvoice(activeCompany.id, billingInvoiceItem.billingInvoiceId),
        );
      }

      return null;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getTaskFromService(service: Service): Promise<Task | null> {
    try {
      return await lastValueFrom(this.servicesHttpService.getTask(service.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async setPaymentMethod(task: Task, paymentMethod: string): Promise<Task | null> {
    try {
      return await lastValueFrom(this.servicesHttpService.postPaymentMethod(task.id, paymentMethod));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async canProcessServicePayment(activeCompany: Company): Promise<CanProcess | null> {
    try {
      return await lastValueFrom(this.servicesHttpService.getProcessPayment(activeCompany.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async completeTask(task: Task): Promise<Task | null> {
    try {
      return await lastValueFrom(this.servicesHttpService.completeTask(task.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async changeCreditCard(company: Company, clientSecret: string): Promise<boolean> {
    try {
      await lastValueFrom(this.servicesHttpService.postCreditCard(company.id, clientSecret));

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async getStripeClientSecret(company: Company): Promise<string | null> {
    try {
      return await lastValueFrom(this.servicesHttpService.postStripeClientSecret(company.id));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async refreshServicesCatalog(company: Company): Promise<void> {
    try {
      const catalog: ServicesCatalog = await toPromise(this.servicesHttpService.getServicesCatalog(company.id));
      this.setState({
        catalog,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshServicesCatalogSearchResult(company: Company, search?: string): Promise<void> {
    try {
      const searchResult: ServicesCatalogSearchResult = await toPromise(
        this.servicesHttpService.searchServicesInCatalog(company.id, search),
      );
      this.setState({
        searchResult,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
